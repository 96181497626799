import React, {Component, createContext} from 'react';
import PropTypes from 'prop-types';
import ProtectedApi from '../services/ProtectedApi';
import PublicApi from '../services/PublicApi';

const AuthContext = createContext({});
const {Provider} = AuthContext;

class AuthProvider extends Component {
    constructor(props) {
        super(props);

        this.protectedApi = new ProtectedApi(this);
        this.publicApi = new PublicApi();

        const isLoggedIn = localStorage.getItem('isLoggedIn');
        const expiresAt = localStorage.getItem('expiresAt');

        this.state = {
            isLoggedIn: isLoggedIn ? isLoggedIn : false,
            expiresAt: expiresAt
        };
    }

    setAuthInfo = ({ isLoggedIn, expiresAt }) => {
        localStorage.setItem('isLoggedIn', isLoggedIn);
        localStorage.setItem('expiresAt', expiresAt);

        this.setState({
            isLoggedIn: isLoggedIn,
            expiresAt: expiresAt
        });
    };

    consideredAuthenticated = () => {
        // TODO: expires at check (UX).
        return this.state.isLoggedIn;
    };

    clearStorage = () => {
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('expiresAt');
        this.setState({
            isLoggedIn: false
        });
    };

    logoutAndClear = async () => {
        this.clearStorage();
        const response = await this.publicApi.logout();

        this.setState({
            logoutSuccess: response.success
        });
    };

    componentDidMount() {
        if (this.state.logoutSuccess) {
            this.setState({
                logoutSuccess: false
            }, () => {
                this.props.navigation.navigate(
                    '/login',
                    {
                        logoutSuccess: true
                    }
                );
            });
        }
    }

    render() {
        return (
            <Provider
                value={{
                    setCurCategory: id => this.setCurCategory(id),
                    setAuthInfo: authInfo => this.setAuthInfo(authInfo),
                    consideredAuthenticated: () => this.consideredAuthenticated(),
                    clearStorage: () => this.clearStorage(),
                    logoutAndClear: () => this.logoutAndClear(),
                    api: this.protectedApi,
                }}
            >
                {this.props.children}
            </Provider>
        );
    }
}

AuthProvider.propTypes = {
    children: PropTypes.array,
    navigation: PropTypes.object
};

export { AuthContext, AuthProvider };
