import {Button, Dropdown, Form} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faArrowDown,
    faArrowUp,
    faEllipsisVertical,
    faPlusCircle,
    faTrash,
    faTree,
    faWrench
} from '@fortawesome/free-solid-svg-icons';
import ButtonTooltip from './ButtonTooltip';
import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import CategoryForm from './CategoryForm';
import {AuthContext} from '../context/AuthContext';
import Tree from './Tree';
import ConfirmDialog from './ConfirmDialog';


export default function TreeMenu({ setState, homeState, reloadData }) {
    const authContext = useContext(AuthContext);

    const categoryObject = homeState.categories.find(
        ({category_id}) => parseInt(category_id) === homeState.curCategory
    );

    const createCategory = async function(event) {
        event.preventDefault();

        const categoryId = document.getElementById('category-id').value;
        const categoryText = document.getElementById('category-text').value;

        // update category
        if (categoryId) {
            // updating current category as well
            await authContext.api.kh_update_category(homeState.curCategory, categoryText);
            await reloadData();
        }
        // create sub-category
        else {
            const categoryParentId = document.getElementById('is-root-category').checked && 1 || homeState.curCategory;
            const response = await authContext.api.kh_create_subcategory(categoryParentId, categoryText);
            const newCategoryId = parseInt(response.newCategoryId);

            await reloadData();

            const response2 = await authContext.api.kh_get_by_category(newCategoryId);
            setState({
                curCategory: newCategoryId,
                categoryTopics: response2.topics,
                topicCodes: response2.codes
            });
        }
    };

    const moveCategory = async function(up) {
        await authContext.api.move_category_up_or_down(homeState.curCategory, up);
        await reloadData();
    };

    const handleMoveCategory = async function(target_category_id) {
        // different categories makes sense
        if (homeState.curCategory !== target_category_id) {
            const response = await authContext.api.kh_move_category(homeState.curCategory, target_category_id);
            if (!response.success) {
                setState({
                    errorMessage: 'You can\'t move category to its child category.'
                });
            }
        }

        setState({
            moveCategoryMode: false
        });

        await reloadData();
    };

    const deleteCategory = async function(event) {
        event.preventDefault();
        const response = await authContext.api.kh_delete_category(homeState.curCategory);

        if (response.success) {
            setState({
                curCategory: parseInt(homeState.categories[0].category_id)
            });

            await reloadData();
        } else {
            setState({
                errorMessage: 'You can\'t delete category containing subcategories.'
            });
        }
    };

    return (
        <div className='left-container'>
            <div>
                <Form.Check
                    type='switch'
                    id='move-mode-switch'
                    label='Moving mode'
                    checked={homeState.moveCategoryMode}
                    onChange={() => setState({
                        moveCategoryMode: !homeState.moveCategoryMode
                    })}
                />

                <Dropdown className={'float-end'} style={{marginRight: '20px'}}>
                    <Dropdown.Toggle variant='secondary' id='dropdown-basic' size='sm'>
                        <FontAwesomeIcon icon={faEllipsisVertical}/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item
                            onClick={() => {
                                setState({
                                    categoryFormShown: true,
                                    categoryFormData: {}
                                });
                            }}>
                            <FontAwesomeIcon icon={faPlusCircle}/> Create
                        </Dropdown.Item>

                        <Dropdown.Item
                            onClick={() => {
                                setState({
                                    categoryFormShown: true,
                                    categoryFormData: {
                                        category_id: categoryObject.category_id.toString(),
                                        category_name: categoryObject.category_name
                                    }
                                });
                            }}>
                            <FontAwesomeIcon icon={faWrench}/> Edit
                        </Dropdown.Item>

                        <Dropdown.Item
                            onClick={() => {
                                setState({
                                    deleteCategoryConfirmationDialogShown: true
                                });
                            }}>
                            <FontAwesomeIcon icon={faTrash}/> Delete
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <span style={{marginRight: '5px'}}>
                    <ButtonTooltip
                        disabled={!homeState.moveCategoryMode}
                        tooltipText={'Moving mode is not enabled.'}>
                        <Button
                            size='sm'
                            variant={'primary'}
                            onClick={() => moveCategory(true)}>
                            <FontAwesomeIcon icon={faArrowUp}/>
                        </Button>
                    </ButtonTooltip>
                </span>

                <span style={{marginRight: '5px'}}>
                    <ButtonTooltip
                        disabled={!homeState.moveCategoryMode}
                        tooltipText={'Moving mode is not enabled.'}>
                        <Button
                            size='sm'
                            variant={'primary'}
                            onClick={() => moveCategory(false)}>
                            <FontAwesomeIcon icon={faArrowDown}/>
                        </Button>
                    </ButtonTooltip>
                </span>


                <span style={{marginRight: '5px'}}>
                    <ButtonTooltip
                        disabled={!homeState.moveCategoryMode}
                        tooltipText='Moving mode is not enabled.'>
                        <Button
                            size='sm'
                            variant='secondary'
                            onClick={() => {
                                handleMoveCategory(1);
                                setState({
                                    moveCategoryMode: false
                                });
                            }}>
                            <FontAwesomeIcon icon={faTree}/>
                        </Button>
                    </ButtonTooltip>
                </span>
            </div>

            <div className='tree left'>
                {
                    homeState.contentLoaded &&
                    homeState.graph.pseudoRoots.map((root, idx) => {
                        return (
                            <Tree
                                key={'tree-' + idx}
                                graph={homeState.graph}
                                curVertexId={root}
                                parentId={null}
                                categories={homeState.categories}
                                presentCategory={homeState.curCategory}
                                setCurCategory={async (id) => {
                                    const resp = await authContext.api.kh_get_by_category(id);
                                    setState({
                                        curCategory: id,
                                        curTopicIdx: 0,
                                        curCodeIdx: 0,
                                        categoryTopics: resp.topics,
                                        topicCodes: resp.codes,
                                    });
                                }
                                }
                                moveCategoryMode={homeState.moveCategoryMode}
                                handleMove={handleMoveCategory}
                                parentExpanded={true}
                            />
                        );
                    })
                }
            </div>

            {/* Modals */}
            <CategoryForm
                hide={() => {
                    setState({
                        categoryFormShown: false
                    });
                }}
                isVisible={homeState.categoryFormShown}
                onSubmit={(evt) => createCategory(evt)}
                formData={homeState.categoryFormData}
            />

            <ConfirmDialog
                message='Do you really want to permanently delete this category and all the corresponding topics and codes?'
                title='Delete category confirmation'
                isVisible={homeState.deleteCategoryConfirmationDialogShown}
                onSubmit={(event) => deleteCategory(event)}
                onCancel={() => setState({
                    deleteCategoryConfirmationDialogShown: false
                })}
            />
        </div>
    );
}

TreeMenu.propTypes = {
    homeState: PropTypes.object,
    setState: PropTypes.func,
    reloadData: PropTypes.func,
};
