import React, {Component} from 'react';
import {AuthContext} from '../context/AuthContext';
import {Navigate} from 'react-router-dom';
import Tree from '../components/Tree';
import ConfirmDialog from '../components/ConfirmDialog';
import CategoryForm from '../components/CategoryForm';
import Notification from '../components/Notification';
import TreeMenu from '../components/TreeMenu';
import TopicHeader from '../components/TopicHeader';
import MarkdownCarousel from '../components/MarkdownCarousel';
import CodeHeader from '../components/CodeHeader';


export default class Home extends Component {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);

        this.state = {
            activeMode: 'topic-container',
            curCategory: parseInt(localStorage.getItem('lastCategoryIdx') || 0),
            curTopicIdx: parseInt(localStorage.getItem('lastTopicIdx') || 0),
            curCodeIdx: parseInt(localStorage.getItem('lastCodeIdx') || 0),
            // true iff categories are loaded and graph is build
            //      and topics are downloaded.
            contentLoaded: false,
            // list of topics from current active category
            categoryTopics: [],
            // list of codes from current active topic
            topicCodes: [],
            // contains JSONs:
            //      {category_id, category_name, category_order, category_parent_id, description}
            //      all in the string form!
            categories: [],
            // true iff delete category form is shown
            deleteCategoryConfirmationDialogShown: false,
            // true iff form for creating or editing categories is shown
            categoryFormShown: false,
            // data for prefilling category form, i.e. when we are editing
            // we want to prefill current category name, but when creating new
            // subcategory we don't want to prefill current category.
            categoryFormData: {},
            // true iff we are in moving mode
            moveCategoryMode: false,
            // graph of categories in the edge representation.
            // Pseudo-roots are those categories who are just
            // under virtual root, called 'root'.
            graph: {
                root: null,
                edges: [],
                pseudoRoots: []
            }
        };
    }

    async loadData() {
        let {topics: topics, codes: codes} = await this.context.api.kh_get_by_category(this.state.curCategory);
        // unsortedTopics = [].concat(unsortedTopics);
        // unsortedCodes = [].concat(unsortedCodes);
        // const sortedTopics = unsortedTopics.sort((t1, t2) => t1.topic_order - t2.topic_order);

        // TODO: sorting should happen on server, so there is no need to sort on front-end.
        //  Let's comment it out for a moment.
        // let sortedCodes = [];
        // if (sortedTopics.length > 0) {
        //     const topicId = sortedTopics[this.state.curTopicIdx].topic_id;
        //     unsortedCodes = unsortedCodes.filter(
        //         ({topic_id}) => parseInt(topic_id) === parseInt(topicId)
        //     );
        //     sortedCodes = unsortedCodes.sort((t1, t2) => t1.code_order - t2.code_order);
        // }


        // fetching of private endpoint without being authenticated (no valid SID) will result
        // in redirect to login page by default behaviour of the API.
        this.setState({
            categories: await this.context.api.kh_categories(),
            categoryTopics: topics,
            topicCodes: codes,
        }, () => {
            // create graph in format [parent_id, category_id, full category object with ID = CATEGORY_ID]
            let edges = this.state.categories.map(
                category => [
                    parseInt(category.category_parent_id),
                    parseInt(category.category_id), category
                ]
            );

            // root categories are those which has PARENT_ID = CATEGORY_ID
            // using destructuring assignment
            const roots = edges.filter(
                ([parent_id, category_id]) => parent_id === category_id
            );
            if (roots.length > 1) {
                this.setState({
                    errorMessage: 'Two virtual roots has been found. You can\'t have more than one virtual root.'
                });

                return;
            } else if (roots.length === 0) {
                this.setState({
                    errorMessage: 'You don\'t have any virtual roots. You have to have exactly one virtual root.'
                });

                return;
            }

            let root = roots[0];
            const [root_parent_id, root_category_id, _] = root;

            // pseudo-roots are those which are just under the virtual root
            const pseudoRoots = edges.filter(([parent_id, category_id]) => parent_id === root_category_id && parent_id !== category_id)
                .sort((e1, e2) => e1[2].category_order - e2[2].category_order)
                .map(edge => edge[1]);

            // remove virtual root from edges
            edges = edges.filter(edge => edge[0] !== edge[1]);

            this.setState({
                graph: {
                    root: root,
                    edges: edges,
                    pseudoRoots: pseudoRoots
                }
            }, () => {
                this.setState({
                    contentLoaded: true
                });
            });
        });
    }

    async componentDidMount() {
        await this.loadData();
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        localStorage.setItem('lastCategoryIdx', this.state.curCategory);
        localStorage.setItem('lastTopicIdx', this.state.curTopicIdx);
        localStorage.setItem('lastCodeIdx', this.state.curCodeIdx);
    }

    render() {
        // user is not considered authenticated
        // (no isLoggedIn flag in Local Storage) => redirect to login
        if (!this.context.consideredAuthenticated()) {
            return (<Navigate
                to={{
                    pathname: '/login',
                    state: {
                        toastMessage: 'You are already logged in.'
                    }
                }} />);
        }

        // user is considered logged in => provide private content
        return (
            <div className='panel-container'>

                {/* Category tree */}
                {
                    this.state.contentLoaded &&
                        <TreeMenu
                            setState={(json) => this.setState(json)}
                            homeState={this.state}
                            reloadData={() => this.loadData()}/>
                }

                {/* Topic carousel */}
                <div className='right'>
                    {
                        this.state.contentLoaded &&
                            <>
                                {
                                    this.state.activeMode === 'topic-container' &&
                                        <MarkdownCarousel
                                            id='topic-container'
                                            data={this.state.categoryTopics}
                                            curDataIdx={this.state.curTopicIdx}
                                            reloadData={() => this.loadData()}
                                            field='topic_text'>
                                            <TopicHeader
                                                setActiveMode={() => {
                                                    this.setState({
                                                        activeMode: 'code-container'
                                                    });
                                                }}
                                                categoryTopics={this.state.categoryTopics}
                                                curTopicIdx={this.state.curTopicIdx}
                                                setTopicIdx={async (idx) => {
                                                    this.setState({
                                                        curTopicIdx: idx,
                                                        curCodeIdx: 0
                                                    });
                                                }}
                                                curCategory={this.state.curCategory}
                                                reloadData={() => this.loadData()}
                                            />
                                        </MarkdownCarousel>
                                }

                                {
                                    this.state.activeMode === 'code-container' &&
                                        <MarkdownCarousel
                                            id='code-container'
                                            data={this.state.topicCodes}
                                            curDataIdx={this.state.curCodeIdx}
                                            reloadData={() => this.loadData()}
                                            field='code_text'>
                                            <CodeHeader
                                                setActiveMode={() => {
                                                    this.setState({
                                                        activeMode: 'topic-container'
                                                    });
                                                }}
                                                topicCodes={this.state.topicCodes}
                                                curCodeIdx={this.state.curCodeIdx}
                                                setCodeIdx={(idx) => {
                                                    this.setState({
                                                        curCodeIdx: idx
                                                    });
                                                }}
                                                curTopic={
                                                    (this.state.categoryTopics.length !== 0) ?
                                                        parseInt(this.state.categoryTopics[this.state.curTopicIdx].topic_id)
                                                        :
                                                        -1}
                                                reloadData={() => this.loadData()}
                                            />
                                        </MarkdownCarousel>
                                }
                            </>
                    }
                </div>


                {/* Dialogs & Error messages */}

                {
                    this.state.errorMessage && (
                        <Notification
                            header='An error has occurred'
                            body={this.state.errorMessage}
                            onClose={
                                () => {
                                    this.setState({
                                        errorMessage: null
                                    });
                                }
                            }/>
                    )
                }
            </div>
        );
    }
}
