// Bootstrap
import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {AuthContext} from '../context/AuthContext';

export default function Carousel({ id, data, curDataIdx, field, children, parser }) {
    const authContext = useContext(AuthContext);

    // database column can contain 'null' values
    // let's show it to the user.
    if (curDataIdx < data.length && data[curDataIdx][field] === null) {
        data[curDataIdx][field] = 'Item is *NULL*.';
    }

    return (
        <div className='jumbotron-container carousel-container allow-vertical-scrollbar' id={id}>
            {children}

            {data.length === 0 && <p><em>No item...</em></p> || (
                <div
                    id='topic-view-area'
                    style={{whiteSpace: 'normal'}}
                    dangerouslySetInnerHTML={{__html: parser(data[curDataIdx][field])}} />
            )}
        </div>
    );
}

Carousel.propTypes = {
    id: PropTypes.string,
    data: PropTypes.array,
    curDataIdx: PropTypes.number,
    children: PropTypes.node,
    parser: PropTypes.func,
    field: PropTypes.string
};
