import React from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Modal} from 'react-bootstrap';

export default function ConfirmDialog({isVisible, onSubmit, onCancel, message, title}) {
    return (
        <Modal show={isVisible} onHide={onCancel} dialogClassName="confirm-modal">
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {message}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onCancel}>
                    Close
                </Button>
                <Form onSubmit={(event) => onSubmit(event)}>
                    <Button variant="danger" style={{float: 'right'}} type="submit" onClick={onCancel}>
                        Delete
                    </Button>
                </Form>
            </Modal.Footer>
        </Modal>
    );
}

ConfirmDialog.propTypes = {
    isVisible: PropTypes.bool,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    message: PropTypes.string,
    title: PropTypes.string
};
