import {Button, Form, Modal} from 'react-bootstrap';
import React, {useRef} from 'react';
import PropTypes from 'prop-types';

export default function TopicForm({isVisible, hide, onSubmit, formData}) {
    const innerRef = useRef();

    return (
        <Modal show={isVisible} onHide={hide} dialogClassName="main-modal" onShow={() => {innerRef.current.focus();}}>
            <Modal.Header closeButton>
                <Modal.Title>{formData.topic_id && 'Update topic' || 'Create topic'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={(event) => onSubmit(event)}>
                    <input type={'hidden'} id={'topic-id'} defaultValue={formData.topic_id}/>
                    <Form.Group className="mb-3">
                        <Form.Control
                            ref={innerRef}
                            as="textarea"
                            rows={10}
                            placeholder='Topic text'
                            id={'topic-text'}
                            defaultValue={formData.topic_text}
                        />
                    </Form.Group>
                    {/*<Form.Group className="mb-3">*/}
                    {/*    <Form.Check*/}
                    {/*        type='checkbox'*/}
                    {/*        id={'is-markdown'}*/}
                    {/*        label={'Is Markdown?'}*/}
                    {/*    />*/}
                    {/*</Form.Group>*/}
                    <Button variant="primary" style={{float: 'right'}} type="submit" onClick={hide}>
                        {formData.topic_id && 'Update topic' || 'Create topic'}
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

TopicForm.propTypes = {
    isVisible: PropTypes.bool,
    hide: PropTypes.func,
    onSubmit: PropTypes.func,
    formData: PropTypes.shape({
        topic_id: PropTypes.string,
        topic_text: PropTypes.string
    })
};
