// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import {useNavigate} from 'react-router-dom';
import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {AuthContext} from '../context/AuthContext';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';
import ButtonTooltip from './ButtonTooltip';
import {Button} from 'react-bootstrap';

export default function Tree({
    graph,
    curVertexId,
    parentId,
    categories,
    presentCategory,
    setCurCategory,
    moveCategoryMode,
    handleMove,
    parentExpanded
}) {
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);

    if (localStorage.getItem('expanded-info') === null) {
        localStorage.setItem('expanded-info', '{}');
    }

    const initialExpandedInfo = JSON.parse(localStorage.getItem('expanded-info'));
    const [isExpanded, setIsExpanded] = useState(initialExpandedInfo[curVertexId] === undefined ? true : initialExpandedInfo[curVertexId]);

    useEffect(() => {
        let newExpandedInfo = JSON.parse(localStorage.getItem('expanded-info'));
        newExpandedInfo[curVertexId] = isExpanded;
        localStorage.setItem('expanded-info', JSON.stringify(newExpandedInfo));
    }, [isExpanded]);

    function getNeigh() {
        const edges = graph.edges.filter(edge => edge[0] === curVertexId)
            .sort((e1, e2) => e1[2].category_order - e2[2].category_order);
        return edges.map(edge => edge[1]);
    }

    function findVertexObject() {
        return categories.find(category => parseInt(category.category_id) === curVertexId);
    }

    const neigh = getNeigh();
    const curVertexObject = findVertexObject();

    if (curVertexObject) {
        const current = curVertexId === presentCategory ? 'bold' : 'normal';
        const classes = `tree-node ${current} d-inline`;
        const x = (
            <div style={{whiteSpace: 'nowrap'}}>
                <li key={'li-' + curVertexId} className={classes} onClick={() => {
                    if (moveCategoryMode) {
                        handleMove(curVertexId);
                    }
                }}>
                    <p
                        className='d-inline'
                        style={{color: moveCategoryMode && 'blue', fontStyle: moveCategoryMode && 'italic', marginRight: '5px'}}
                        onClick={() => {
                            if (!moveCategoryMode) {
                                setCurCategory(curVertexId);
                            }
                        }}>
                        {curVertexObject.category_name}
                    </p>
                </li>

                {neigh.length > 0 &&
                    <div className='d-inline'>
                        <ButtonTooltip
                            tooltipText='Moving mode is currently enabled.'
                            disabled={moveCategoryMode}>
                            <Button
                                size='sm'
                                variant='light'
                                onClick={() => {
                                    // collapse all <ul>'s in the same level
                                    setIsExpanded(!isExpanded);
                                }}>
                                <FontAwesomeIcon icon={isExpanded ? faChevronDown : faChevronUp} />
                            </Button>
                        </ButtonTooltip>
                    </div>
                }
            </div>
        );
        if (neigh.length > 0) {
            return (
                <ul key={'ul-' + curVertexId} className={!parentExpanded ? 'not-expanded' : ''}>
                    {x}
                    {
                        neigh.map(neighId => (
                            <Tree
                                graph={graph}
                                curVertexId={neighId}
                                parentId={curVertexId}
                                key={'tree-' + neighId}
                                categories={categories}
                                setCurCategory={setCurCategory}
                                presentCategory={presentCategory}
                                moveCategoryMode={moveCategoryMode}
                                handleMove={handleMove}
                                parentExpanded={isExpanded}
                            />
                        ))
                    }
                </ul>
            );
        } else {
            return (
                <ul className={!parentExpanded ? 'not-expanded' : ''}>
                    {x}
                </ul>
            );
        }
    }
}

Tree.propTypes = {
    graph: PropTypes.object,
    curVertexId: PropTypes.number,
    parentId: PropTypes.number,
    categories: PropTypes.array,
    presentCategory: PropTypes.number,
    setCurCategory: PropTypes.func,
    moveCategoryMode: PropTypes.bool,
    handleMove: PropTypes.func,
    parentExpanded: PropTypes.bool
};
