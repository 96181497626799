import {Button, Dropdown} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faArrowRightArrowLeft,
    faEllipsisVertical, faExpand, faLeftLong,
    faLongArrowLeft,
    faLongArrowRight,
    faPlusCircle, faRightLong,
    faTrash,
    faWrench
} from '@fortawesome/free-solid-svg-icons';
import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import {AuthContext} from '../context/AuthContext';
import CodeForm from './CodeForm';
import ConfirmDialog from './ConfirmDialog';


export default function CodeHeader({ setActiveMode, topicCodes, curCodeIdx, setCodeIdx, curTopic, curCategory, reloadData }) {
    const authContext = useContext(AuthContext);

    // if code index is invalid (i.e. out of range), set it to zero
    if (curCodeIdx !== 0 && curCodeIdx >= topicCodes.length) {
        setCodeIdx(0);
        return null;
    }

    // form data used to prefill the CREATE/UPDATE code form
    const [codeFormData, setCodeFormData] = useState({});

    const [isCodeFormShown, setIsCodeFormShown] = useState(false);
    const closeCodeForm = () => {
        setCodeFormData({});
        setIsCodeFormShown(false);
    };

    const [isDeleteCodeFormShown, setIsDeleteCodeFormShown] = useState(false);

    const createCode = async (event) => {
        event.preventDefault();

        const codeId = document.getElementById('code-id').value;
        const codeText = document.getElementById('code-text').value;

        // save code
        const response = await authContext.api.kh_create_code_for_topic(codeId, curTopic, codeText);

        // successfully created new code => navigate to it
        if (response.success === 'true' && !codeId) {
            await reloadData();
            setCodeIdx(topicCodes.length);
            return;
        }

        reloadData();
    };

    /**
     *
     * @param event
     * @param moveToLeft            If true, then move to left. Move right otherwise.
     * @returns {Promise<void>}
     */
    const moveCode = async (event, moveToLeft) => {
        event.preventDefault();

        await authContext.api.kh_move_code(topicCodes[curCodeIdx].code_id, moveToLeft);

        if (moveToLeft) {
            setCodeIdx(curCodeIdx - 1);
        } else {
            setCodeIdx(curCodeIdx + 1);
        }

        reloadData();
    };

    const deleteCode = async (event, codeId) => {
        event.preventDefault();

        setCodeIdx(Math.max(0, curCodeIdx - 1));

        await authContext.api.kh_delete_code(codeId);

        reloadData();
    };

    const currentPresentationIdx = topicCodes.length > 0 && curCodeIdx + 1 || 0;
    const total = topicCodes.length > 0 && topicCodes.length || 0;

    return (
        <div>
            <h4 style={{display: 'inline-block'}}>Code ({currentPresentationIdx}/{total})</h4>
            <div className={'float-end'}>
                <Dropdown className={'float-end'}>
                    <Dropdown.Toggle variant='secondary' id='dropdown-basic' size='sm'>
                        <FontAwesomeIcon icon={faEllipsisVertical} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item
                            onClick={() => setIsCodeFormShown(true)}>
                            <FontAwesomeIcon icon={faPlusCircle} /> Create
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => {
                                setCodeFormData({
                                    code_id: topicCodes[curCodeIdx].code_id,
                                    code_text: topicCodes[curCodeIdx].code_text,
                                });
                                setIsCodeFormShown(true);
                            }}
                            disabled={topicCodes.length === 0}>
                            <FontAwesomeIcon icon={faWrench} /> Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={(event) => moveCode(event, true)}
                            disabled={topicCodes.length === 0 || curCodeIdx === 0}>
                            <FontAwesomeIcon icon={faLongArrowLeft} /> Move left
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={(event) => moveCode(event, false)}
                            disabled={topicCodes.length === 0 || curCodeIdx === topicCodes.length - 1}>
                            <FontAwesomeIcon icon={faLongArrowRight} /> Move right
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => setIsDeleteCodeFormShown(true)}
                            disabled={topicCodes.length === 0}>
                            <FontAwesomeIcon icon={faTrash} /> Delete
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Button
                    className='float-end'
                    size='sm'
                    style={{marginRight: '10px'}}
                    onClick={() => {
                        setCodeIdx(curCodeIdx + 1);
                    }}
                    disabled={curCodeIdx >= topicCodes.length - 1}>
                    <FontAwesomeIcon icon={faRightLong} />
                </Button>
                <Button
                    className='float-end'
                    size='sm'
                    style={{marginRight: '10px'}}
                    onClick={() => {
                        setCodeIdx(curCodeIdx - 1);
                    }}
                    disabled={curCodeIdx === 0}>
                    <FontAwesomeIcon icon={faLeftLong} />
                </Button>
                <Button
                    className='float-end'
                    size='sm'
                    style={{marginRight: '10px'}}
                    onClick={(evt) => document.getElementById('code-container').classList.toggle('fullscreen')}>
                    <FontAwesomeIcon icon={faExpand} />
                </Button>
                <Button
                    className='float-end'
                    size='sm'
                    style={{marginRight: '10px'}}
                    onClick={() => setActiveMode()}>
                    <FontAwesomeIcon icon={faArrowRightArrowLeft} />
                </Button>
            </div>
            <hr style={{margin: '5px 0'}}/>

            <CodeForm
                reloadData={reloadData}
                hide={() => closeCodeForm()}
                isVisible={isCodeFormShown}
                onSubmit={createCode}
                formData={codeFormData}
            />

            {/* Code delete confirmation dialog */}
            <ConfirmDialog
                message={'Do you really want to permanently delete this code?'}
                title={'Delete code confirmation'}
                isVisible={isDeleteCodeFormShown}
                onSubmit={(event) => deleteCode(event, topicCodes[curCodeIdx].code_id)}
                onCancel={() => setIsDeleteCodeFormShown(false)}
            />
        </div>
    );
}

CodeHeader.propTypes = {
    setActiveMode: PropTypes.func,
    curCategory: PropTypes.number,
    curTopic: PropTypes.number,
    reloadData: PropTypes.func,
    topicCodes: PropTypes.array,
    curCodeIdx: PropTypes.number,
    setCodeIdx: PropTypes.func
};
