import {Button, Form, Modal} from 'react-bootstrap';
import React, {useRef} from 'react';
import PropTypes from 'prop-types';

export default function CategoryForm({isVisible, hide, onSubmit, formData}) {
    const innerRef = useRef();

    return (
        <Modal show={isVisible} onHide={hide} dialogClassName="main-modal" onShow={() => {innerRef.current.focus();}}>
            <Modal.Header closeButton>
                <Modal.Title>{formData.category_id && 'Update subcategory' || 'Create subcategory'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={(event) => onSubmit(event)}>
                    <input type={'hidden'} id={'category-id'} defaultValue={formData.category_id}/>
                    <input type={'hidden'} id={'category_parent_id'} defaultValue={formData.category_parent_id}/>
                    <Form.Group className="mb-3">
                        <Form.Control
                            ref={innerRef}
                            placeholder='Subcategory name'
                            id='category-text'
                            defaultValue={formData.category_name}
                        />
                    </Form.Group>
                    {!formData.category_id &&
                        <Form.Group className="mb-3">
                            <Form.Check
                                type='checkbox'
                                id={'is-root-category'}
                                label={'Root category'}
                            />
                        </Form.Group>
                    }
                    <Button variant="primary" style={{float: 'right'}} type="submit" onClick={hide}>
                        {formData.category_id && 'Update subcategory' || 'Create subcategory'}
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

CategoryForm.propTypes = {
    isVisible: PropTypes.bool,
    hide: PropTypes.func,
    onSubmit: PropTypes.func,
    formData: PropTypes.shape({
        category_id: PropTypes.string,
        category_name: PropTypes.string,
        category_parent_id: PropTypes.string
    })
};
