import React, {useState} from 'react';
import {Toast, ToastContainer} from 'react-bootstrap';
import PropTypes from 'prop-types';

export default function Notification(props) {
    const [show, setShow] = useState(true);
    return (
        <ToastContainer
            className="p-3"
            position={'top-end'}>
            <Toast
                onClose={() => { setShow(false); props.onClose(); }}
                show={show}
                delay={1000000}
                bg={'light'}// for no-opacity it is needed to set the 'bg'
                autohide>
                <Toast.Header closeButton={true}>
                    <strong className="me-auto">{props.header}</strong>
                    <small>About now</small>
                </Toast.Header>
                <Toast.Body>{props.body}</Toast.Body>
            </Toast>
        </ToastContainer>
    );
}

Notification.propTypes = {
    body: PropTypes.string,
    header: PropTypes.string,
    onClose: PropTypes.func
};
