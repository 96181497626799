import {Button, Dropdown} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faArrowRightArrowLeft,
    faArrowsLeftRight,
    faEllipsisVertical,
    faExpand, faLeftLong,
    faLongArrowLeft,
    faLongArrowRight,
    faPlusCircle, faRightLong,
    faTrash,
    faWrench
} from '@fortawesome/free-solid-svg-icons';
import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {AuthContext} from '../context/AuthContext';
import TopicForm from './TopicForm';
import ConfirmDialog from './ConfirmDialog';


export default function TopicHeader({ setActiveMode, categoryTopics, curTopicIdx, setTopicIdx, curCategory, reloadData }) {
    const authContext = useContext(AuthContext);

    // if topic index is invalid (i.e. out of range), set it to zero
    if (curTopicIdx !== 0 && curTopicIdx >= categoryTopics.length) {
        setTopicIdx(0);
        return null;
    }

    useEffect(() => {
        reloadData();
    }, [curTopicIdx]);

    // form data used to prefill the CREATE/UPDATE topic form
    const [topicFormData, setTopicFormData] = useState({});

    const [isTopicFormShown, setIsTopicFormShown] = useState(false);
    const closeTopicForm = () => {
        setTopicFormData({});
        setIsTopicFormShown(false);
    };

    const [isDeleteTopicFormShown, setIsDeleteTopicFormShown] = useState(false);

    const createTopic = async (event) => {
        event.preventDefault();

        const topicId = document.getElementById('topic-id').value;
        const topicText = document.getElementById('topic-text').value;

        // save topic
        const response = await authContext.api.kh_create_topic(topicId, curCategory, topicText);

        // successfully created new topic => navigate to it
        if (response.success === 'true' && !topicId) {
            await reloadData();
            setTopicIdx(categoryTopics.length);
            return;
        }

        reloadData();
    };

    /**
     *
     * @param event
     * @param moveToLeft            If true, then move to left. Move right otherwise.
     * @returns {Promise<void>}
     */
    const moveTopic = async (event, moveToLeft) => {
        event.preventDefault();

        await authContext.api.kh_move_topic(categoryTopics[curTopicIdx].topic_id, moveToLeft);

        if (moveToLeft) {
            setTopicIdx(curTopicIdx - 1);
        } else {
            setTopicIdx(curTopicIdx + 1);
        }

        reloadData();
    };

    const deleteTopic = async (event, topicId) => {
        event.preventDefault();

        setTopicIdx(Math.max(0, curTopicIdx - 1));

        await authContext.api.kh_delete_topic_and_its_codes(topicId);

        reloadData();
    };

    const currentPresentationIdx = categoryTopics.length > 0 && curTopicIdx + 1 || 0;
    const total = categoryTopics.length > 0 && categoryTopics.length || 0;

    return (
        <div>
            <h4 style={{display: 'inline-block'}}>Topic ({currentPresentationIdx}/{total})</h4>
            <div className={'float-end'}>
                <Dropdown className={'float-end'}>
                    <Dropdown.Toggle variant='secondary' id='dropdown-basic' size='sm'>
                        <FontAwesomeIcon icon={faEllipsisVertical} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item
                            onClick={() => setIsTopicFormShown(true)}>
                            <FontAwesomeIcon icon={faPlusCircle} /> Create
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => {
                                setTopicFormData({
                                    topic_id: categoryTopics[curTopicIdx].topic_id,
                                    topic_text: categoryTopics[curTopicIdx].topic_text,
                                });
                                setIsTopicFormShown(true);
                            }}
                            disabled={categoryTopics.length === 0}>
                            <FontAwesomeIcon icon={faWrench} /> Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={(event) => moveTopic(event, true)}
                            disabled={categoryTopics.length === 0 || curTopicIdx === 0}>
                            <FontAwesomeIcon icon={faLongArrowLeft} /> Move left
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={(event) => moveTopic(event, false)}
                            disabled={categoryTopics.length === 0 || curTopicIdx === categoryTopics.length - 1}>
                            <FontAwesomeIcon icon={faLongArrowRight} /> Move right
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => setIsDeleteTopicFormShown(true)}
                            disabled={categoryTopics.length === 0}>
                            <FontAwesomeIcon icon={faTrash} /> Delete
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Button
                    className='float-end'
                    size='sm'
                    style={{marginRight: '10px'}}
                    onClick={() => {
                        setTopicIdx(curTopicIdx + 1);
                    }}
                    disabled={curTopicIdx >= categoryTopics.length - 1}>
                    <FontAwesomeIcon icon={faRightLong} />
                </Button>
                <Button
                    className='float-end'
                    size='sm'
                    style={{marginRight: '10px'}}
                    onClick={() => {
                        setTopicIdx(curTopicIdx - 1);
                    }}
                    disabled={curTopicIdx === 0}>
                    <FontAwesomeIcon icon={faLeftLong} />
                </Button>
                <Button
                    className='float-end'
                    size='sm'
                    style={{marginRight: '10px'}}
                    onClick={(evt) => document.getElementById('topic-container').classList.toggle('fullscreen')}>
                    <FontAwesomeIcon icon={faExpand} />
                </Button>
                <Button
                    className='float-end'
                    size='sm'
                    style={{marginRight: '10px'}}
                    onClick={() => {
                        setActiveMode();
                    }}>
                    <FontAwesomeIcon icon={faArrowRightArrowLeft} />
                </Button>
            </div>
            <hr style={{margin: '5px 0'}}/>

            <TopicForm
                hide={() => closeTopicForm()}
                isVisible={isTopicFormShown}
                onSubmit={createTopic}
                formData={topicFormData}
            />

            {/* Topic delete confirmation dialog */}
            <ConfirmDialog
                message={'Do you really want to permanently delete this topic & all the corresponding codes?'}
                title={'Delete topic confirmation'}
                isVisible={isDeleteTopicFormShown}
                onSubmit={(event) => deleteTopic(event, categoryTopics[curTopicIdx].topic_id)}
                onCancel={() => setIsDeleteTopicFormShown(false)}
            />
        </div>
    );
}

TopicHeader.propTypes = {
    setActiveMode: PropTypes.func,
    curCategory: PropTypes.number,
    reloadData: PropTypes.func,
    categoryTopics: PropTypes.array,
    curTopicIdx: PropTypes.number,
    setTopicIdx: PropTypes.func
};
