import {Button, Form, Modal} from 'react-bootstrap';
import React, {useContext, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {AuthContext} from '../context/AuthContext';

export default function CodeForm({isVisible, hide, onSubmit, formData}) {
    const authContext = useContext(AuthContext);

    const innerRef = useRef();

    const [languages, setLanguages] = useState([]);
    useEffect(() => {
        (async () => {
            setLanguages(await authContext.api.kh_get_languages());
        })();
    }, []);

    return (
        <Modal show={isVisible} onHide={hide} dialogClassName="main-modal" onShow={() => {innerRef.current.focus();}}>
            <Modal.Header closeButton>
                <Modal.Title>{formData.code_id && 'Update code' || 'Create code'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={(event) => onSubmit(event)}>
                    <input type={'hidden'} id={'code-id'} defaultValue={formData.code_id}/>
                    {/*<Form.Group className="mb-3">*/}
                    {/*    <Form.Control*/}
                    {/*        placeholder='Code name'*/}
                    {/*        id={'code-name'}*/}
                    {/*        defaultValue={formData.code_name}*/}
                    {/*        autoFocus*/}
                    {/*    />*/}
                    {/*</Form.Group>*/}
                    <Form.Group className="mb-3">
                        <Form.Control
                            ref={innerRef}
                            as="textarea"
                            rows={10}
                            placeholder='Code text'
                            id={'code-text'}
                            defaultValue={formData.code_text}
                        />
                    </Form.Group>
                    {/*<Form.Group className="mb-3">*/}
                    {/*    <Form.Select id={'code-language'} defaultValue={formData.code_language_id}>*/}
                    {/*        {languages.map(language => {*/}
                    {/*            return <option key={language.language_id} value={language.language_id}>{language.language_name}</option>;*/}
                    {/*        })}*/}
                    {/*    </Form.Select>*/}
                    {/*</Form.Group>*/}
                    <Button variant="primary" style={{float: 'right'}} type="submit" onClick={hide}>
                        {formData.code_id && 'Update code' || 'Create code'}
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

CodeForm.propTypes = {
    isVisible: PropTypes.bool,
    hide: PropTypes.func,
    onSubmit: PropTypes.func,
    formData: PropTypes.shape({
        code_id: PropTypes.string,
        code_name: PropTypes.string,
        code_text: PropTypes.string,
        code_language_id: PropTypes.string
    })
};
