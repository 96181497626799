import React, {useEffect} from 'react';
import Carousel from './Carousel';
import {marked} from 'marked';
import Prism from 'prismjs';
import PropTypes from 'prop-types';

// plugins
import 'prismjs/plugins/line-numbers/prism-line-numbers.min.css';

// styles
import 'prismjs/themes/prism-okaidia.min.css';

// languages
import 'prismjs/components/prism-clike.min';
import 'prismjs/components/prism-javascript.min';
import 'prismjs/components/prism-actionscript.min';
import 'prismjs/components/prism-sql.min';
import 'prismjs/components/prism-apex.min';
import 'prismjs/components/prism-c.min';
import 'prismjs/components/prism-cpp.min';
import 'prismjs/components/prism-arduino.min';
import 'prismjs/components/prism-markup.min';
import 'prismjs/components/prism-csharp.min';
import 'prismjs/components/prism-aspnet.min';
import 'prismjs/components/prism-birb.min';
import 'prismjs/components/prism-bison.min';
import 'prismjs/components/prism-c.min';
import 'prismjs/components/prism-csharp.min';
import 'prismjs/components/prism-cpp.min';
import 'prismjs/components/prism-cfscript.min';
import 'prismjs/components/prism-chaiscript.min';
import 'prismjs/components/prism-cilkc.min';
import 'prismjs/components/prism-cilkcpp.min';
import 'prismjs/components/prism-coffeescript.min';
import 'prismjs/components/prism-ruby.min';
import 'prismjs/components/prism-crystal.min';
import 'prismjs/components/prism-css.min';
import 'prismjs/components/prism-css-extras.min';
import 'prismjs/components/prism-d.min';
import 'prismjs/components/prism-dart.min';
import 'prismjs/components/prism-markup-templating.min';
import 'prismjs/components/prism-django.min';
import 'prismjs/components/prism-ejs.min';
import 'prismjs/components/prism-lua.min';
import 'prismjs/components/prism-etlua.min';
import 'prismjs/components/prism-erb.min';
import 'prismjs/components/prism-fsharp.min';
import 'prismjs/components/prism-firestore-security-rules.min';
import 'prismjs/components/prism-flow.min';
import 'prismjs/components/prism-ftl.min';
import 'prismjs/components/prism-gml.min';
import 'prismjs/components/prism-glsl.min';
import 'prismjs/components/prism-go.min';
import 'prismjs/components/prism-gradle.min';
import 'prismjs/components/prism-groovy.min';
import 'prismjs/components/prism-haml.min';
import 'prismjs/components/prism-handlebars.min';
import 'prismjs/components/prism-haxe.min';
import 'prismjs/components/prism-hlsl.min';
import 'prismjs/components/prism-haskell.min';
import 'prismjs/components/prism-idris.min';
import 'prismjs/components/prism-java.min';
import 'prismjs/components/prism-javadoclike.min';
import 'prismjs/components/prism-javadoc.min';
import 'prismjs/components/prism-jolie.min';
import 'prismjs/components/prism-typescript.min';
import 'prismjs/components/prism-jsdoc.min';
import 'prismjs/components/prism-js-extras.min';
import 'prismjs/components/prism-json.min';
import 'prismjs/components/prism-json5.min';
import 'prismjs/components/prism-jsonp.min';
import 'prismjs/components/prism-js-templates.min';
import 'prismjs/components/prism-kotlin.min';
import 'prismjs/components/prism-php.min';
import 'prismjs/components/prism-latte.min';
import 'prismjs/components/prism-less.min';
import 'prismjs/components/prism-scheme.min';
import 'prismjs/components/prism-lilypond.min';
import 'prismjs/components/prism-liquid.min';
import 'prismjs/components/prism-markdown.min';
import 'prismjs/components/prism-markup-templating.min';
import 'prismjs/components/prism-mongodb.min';
import 'prismjs/components/prism-n4js.min';
import 'prismjs/components/prism-objectivec.min';
import 'prismjs/components/prism-opencl.min';
import 'prismjs/components/prism-parser.min';
import 'prismjs/components/prism-php.min';
import 'prismjs/components/prism-phpdoc.min';
import 'prismjs/components/prism-php-extras.min';
import 'prismjs/components/prism-plsql.min';
import 'prismjs/components/prism-processing.min';
import 'prismjs/components/prism-protobuf.min';
import 'prismjs/components/prism-pug.min';
import 'prismjs/components/prism-purebasic.min';
import 'prismjs/components/prism-purescript.min';
import 'prismjs/components/prism-qsharp.min';
import 'prismjs/components/prism-qml.min';
import 'prismjs/components/prism-qore.min';
import 'prismjs/components/prism-racket.min';
import 'prismjs/components/prism-cshtml.min';
import 'prismjs/components/prism-jsx.min';
import 'prismjs/components/prism-tsx.min';
import 'prismjs/components/prism-reason.min';
import 'prismjs/components/prism-ruby.min';
import 'prismjs/components/prism-sass.min';
import 'prismjs/components/prism-scss.min';
import 'prismjs/components/prism-scala.min';
import 'prismjs/components/prism-bash.min';
import 'prismjs/components/prism-shell-session.min';
import 'prismjs/components/prism-smarty.min';
import 'prismjs/components/prism-solidity.min';
import 'prismjs/components/prism-soy.min';
import 'prismjs/components/prism-turtle.min';
import 'prismjs/components/prism-sparql.min';
import 'prismjs/components/prism-sqf.min';
import 'prismjs/components/prism-squirrel.min';
import 'prismjs/components/prism-mata.min';
import 'prismjs/components/prism-python.min';
import 'prismjs/components/prism-stata.min';
import 'prismjs/components/prism-t4-templating.min';
import 'prismjs/components/prism-t4-cs.min';
import 'prismjs/components/prism-basic.min';
import 'prismjs/components/prism-vbnet.min';
import 'prismjs/components/prism-t4-vb.min';
import 'prismjs/components/prism-yaml.min';
import 'prismjs/components/prism-tap.min';
import 'prismjs/components/prism-tt2.min';
import 'prismjs/components/prism-textile.min';
import 'prismjs/components/prism-twig.min';
import 'prismjs/components/prism-typescript.min';
import 'prismjs/components/prism-v.min';
import 'prismjs/components/prism-vala.min';
import 'prismjs/components/prism-vbnet.min';
import 'prismjs/components/prism-velocity.min';
import 'prismjs/components/prism-wiki.min';
import 'prismjs/components/prism-xeora.min';
import 'prismjs/components/prism-xml-doc.min';
import 'prismjs/components/prism-xquery.min';


/**
 * Carousel that gets Markdown text and supports
 * syntax highlight & LaTeX.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function MarkdownCarousel(props) {
    // Syntax highlight
    const renderer = new marked.Renderer();
    renderer.code = function(code, lang, escaped) {
        if (!lang) {
            return `<pre><code>${code}</code></pre>`;
        }

        code = this.options.highlight(code, lang);

        const langClass = 'language-' + lang;
        return `<pre class="${langClass} line-numbers"><code class="${langClass}">${code}</code></pre>`;
    };

    // add line numbers
    const NEW_LINE_EXP = /\n(?!$)/g;
    let lineNumbersWrapper;
    Prism.hooks.add('after-tokenize', function (env) {
        var match = env.code.match(NEW_LINE_EXP);
        //console.log(match);
        var linesNum = match ? match.length + 1 : 1;
        var lines = new Array(linesNum + 1).join('<span></span>');

        lineNumbersWrapper = `<span aria-hidden="true" class="line-numbers-rows">${lines}</span>`;
    });

    // tell Marked to use the syntax highlight renderer
    marked.setOptions({
        renderer,
        highlight: function(code, lang) {
            try {
                const highlighted = Prism.highlight(code, Prism.languages[lang], lang);
                const withLineNumbers = highlighted + lineNumbersWrapper;
                return withLineNumbers;
            } catch(e) {
                return code;
            }
        }
    });

    // LaTeX after DOM ready
    useEffect(() => {
        if (window.mathJaxReady) {
            window.MathJax.typesetPromise();
        }
    }, [props.data]);

    return (
        <Carousel
            {...props}
            parser={marked.parse}
        />
    );
}

MarkdownCarousel.propTypes = {
    data: PropTypes.array
};
