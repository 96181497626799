import {API_WRAPPER_BASE_URL} from '../globals';


export default class ProtectedApi {

    constructor(context) {
        this.context = context;
    }

    protectedRequestWrapper = async ({ url, method }, rest={}) => {
        return fetch(url, Object.assign({
            method: method,
            headers: {
                'Cookie': document.cookie
            },
            // allow browser to make CORS requests
            // (i.e. requests that have different origin)
            mode: 'cors',
            // include cookies in request headers
            credentials: 'include',
        }, rest)).then(resp => resp.json()).then(resp => {
            // SESSION EXPIRED
            if (Object.prototype.hasOwnProperty.call(resp, 'errorType') && resp.errorType === 'notloggedin') {
                this.context.clearStorage();
            }

            return resp;
        });
    };

    kh_code = async () => {
        return this.protectedRequestWrapper({
            url: `${API_WRAPPER_BASE_URL}/kh_code/all`,
            method: 'GET'
        });
    };

    kh_topic = async () => {
        return this.protectedRequestWrapper({
            url: `${API_WRAPPER_BASE_URL}/get_kh_topic/all`,
            method: 'GET'
        });
    };

    kh_categories = async () => {
        return this.protectedRequestWrapper({
            url: `${API_WRAPPER_BASE_URL}/get_categories/all`,
            method: 'GET'
        });
    };

    kh_get_by_category = async (categoryId) => {
        return this.protectedRequestWrapper({
            url: `${API_WRAPPER_BASE_URL}/get_by_category/${categoryId}`,
            method: 'GET'
        });
    };

    kh_get_languages = async () => {
        return this.protectedRequestWrapper({
            url: `${API_WRAPPER_BASE_URL}/get_languages`,
            method: 'GET'
        });
    };

    kh_create_topic = async (topicId, categoryId, topicText) => {
        return this.protectedRequestWrapper({
            url: `${API_WRAPPER_BASE_URL}/create_topic`,
            method: 'POST'
        }, {
            body: JSON.stringify({
                topicId: topicId,
                topicText: topicText,
                categoryId: categoryId
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        });
    };

    kh_create_subcategory = async (parentCategoryId, categoryName) => {
        return this.protectedRequestWrapper({
            url: `${API_WRAPPER_BASE_URL}/create_subcategory`,
            method: 'POST'
        }, {
            body: JSON.stringify({
                parentCategoryId: parentCategoryId,
                categoryName: categoryName
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        });
    };

    kh_update_category = async (categoryId, newCategoryName) => {
        return this.protectedRequestWrapper({
            url: `${API_WRAPPER_BASE_URL}/update_subcategory`,
            method: 'POST'
        }, {
            body: JSON.stringify({
                categoryId: categoryId,
                newCategoryName: newCategoryName
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        });
    };

    kh_delete_category = async (categoryId) => {
        return this.protectedRequestWrapper({
            url: `${API_WRAPPER_BASE_URL}/delete_category`,
            method: 'POST'
        }, {
            body: JSON.stringify({
                categoryId: categoryId,
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        });
    };

    kh_move_category = async (categoryId, targetCategoryId) => {
        return this.protectedRequestWrapper({
            url: `${API_WRAPPER_BASE_URL}/move_category`,
            method: 'POST'
        }, {
            body: JSON.stringify({
                categoryId: categoryId,
                targetCategoryId: targetCategoryId
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        });
    };

    move_category_up_or_down = async (categoryId, up) => {
        return this.protectedRequestWrapper({
            url: `${API_WRAPPER_BASE_URL}/move_category_up_or_down`,
            method: 'POST'
        }, {
            body: JSON.stringify({
                categoryId: categoryId,
                up: up
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        });
    };

    /**
     *
     * @param codeId - if codeId is specified, it is update, otherwise it is create.
     * @param topicId
     * @param codeName
     * @param languageId
     * @param codeText
     * @returns {Promise<unknown>}
     */
    kh_create_code_for_topic = async (codeId, topicId, codeText) => {
        return this.protectedRequestWrapper({
            url: `${API_WRAPPER_BASE_URL}/create_code_for_topic`,
            method: 'POST'
        }, {
            body: JSON.stringify({
                codeId: codeId,
                codeName: '',
                codeText: codeText,
                topicId: topicId,
                languageId: '1'
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        });
    };

    kh_delete_topic_and_its_codes = async (topicId) => {
        return this.protectedRequestWrapper({
            url: `${API_WRAPPER_BASE_URL}/delete_topic_and_its_codes`,
            method: 'POST'
        }, {
            body: JSON.stringify({
                topicId: topicId
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        });
    };

    kh_delete_code = async (codeId) => {
        return this.protectedRequestWrapper({
            url: `${API_WRAPPER_BASE_URL}/delete_code`,
            method: 'POST'
        }, {
            body: JSON.stringify({
                codeId: codeId
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        });
    };

    kh_move_topic = async (topicId, moveToLeft) => {
        return this.protectedRequestWrapper({
            url: `${API_WRAPPER_BASE_URL}/move_topic`,
            method: 'POST'
        }, {
            body: JSON.stringify({
                topicId: topicId,
                moveToLeft: moveToLeft
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        });
    };

    kh_move_code = async (codeId, moveToLeft) => {
        return this.protectedRequestWrapper({
            url: `${API_WRAPPER_BASE_URL}/move_code`,
            method: 'POST'
        }, {
            body: JSON.stringify({
                codeId: codeId,
                moveToLeft: moveToLeft
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        });
    };

}
