// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import {useNavigate} from 'react-router-dom';
import React, {useContext, useEffect} from 'react';

import {APP_NAME} from '../globals';
import {AuthContext} from '../context/AuthContext';
import PublicApi from '../services/PublicApi';

export default function Navigation() {
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);

    const publicApi = new PublicApi();

    useEffect(() => {
        document.title = APP_NAME;
    }, []);

    return (
        <Navbar bg="light" expand="lg" className={'mb-2 py-0'}>
            <Container>
                <Navbar.Brand style={{'cursor': 'pointer'}} onClick={() => navigate('/home')}>{APP_NAME}</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        {
                            // authContext.consideredAuthenticated() &&
                            //     <Nav.Link onClick={() => navigate('/home')}>Home</Nav.Link>
                        }
                        {
                            authContext.consideredAuthenticated() &&
                                <Nav.Link onClick={
                                    () => {
                                        authContext.logoutAndClear();
                                    }
                                }>Log out</Nav.Link>
                        }
                        {
                            // !authContext.consideredAuthenticated() &&
                            //     <Nav.Link onClick={() => navigate('/login')}>Log in</Nav.Link>
                        }
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
