import {API_WRAPPER_BASE_URL} from '../globals';


export default class PublicApi {
    login = async (password) => {
        return fetch(`${API_WRAPPER_BASE_URL}/login`, {
            method: 'POST',
            body: JSON.stringify({
                password: password
            }),
            headers: {
                'Content-Type': 'application/json',
                'Cookie': document.cookie
            },
            // allow browser to make CORS requests
            // (i.e. requests that have different origin)
            mode: 'cors',
            // include cookies in request headers
            credentials: 'include'
        }).then(resp => resp.json());
    };

    logout = async () => {
        return fetch(`${API_WRAPPER_BASE_URL}/logout`, {
            method: 'POST',
            headers: {
                'Cookie': document.cookie
            },
            // allow browser to make CORS requests
            // (i.e. requests that have different origin)
            mode: 'cors',
            // include cookies in request headers
            credentials: 'include'
        }).then(resp => resp.json());
    };
}
