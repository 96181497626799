import React, {Component} from 'react';


export default class About extends Component {
    render() {
        return (
            <>
                <h1>About this project</h1>
                <p>TODO</p>
            </>
        );
    }
}
