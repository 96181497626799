import PropTypes from 'prop-types';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import React from 'react';

/**
 * Component that adds tooltip to the button iff it is disabled.
 * Component is disabled iff 'disabled' attribute is set to 'false'
 * in the 'props'.
 * @param props
 *      {
 *          tooltipText     - text to show when 'disabled' == 'true',
 *          disabled        - if the button is 'disabled' & the tooltip is shown,
 *          children        - inserted component.
 *      }
 * @returns {JSX.Element}
 * @constructor
 */
export default function ButtonTooltip(props) {
    const button = props.children;

    return !props.disabled && button || (
        <OverlayTrigger
            overlay={<Tooltip id='tooltip-disabled'>{props.tooltipText}</Tooltip>}>
            <span className='d-inline-block'>
                {/* add 'disabled' attribute */}
                {React.cloneElement(button, {disabled: props.disabled})}
            </span>
        </OverlayTrigger>
    );
}

ButtonTooltip.propTypes = {
    disabled: PropTypes.bool,
    tooltipText: PropTypes.string,
    children: PropTypes.node
};
